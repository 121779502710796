// viết script, gọi function ở đây
$(document).ready(() => {

	toolNavMapping();
	zoneNavMapping();
	
	toggleSearchOnClick();
	
	toggleMobileHeader();
	
	const bannerHome = bannerHomeInit();

	if ($('[data-count-id]').length) {
		$('[data-count-id]').each(function() {
			window[$(this)[0].dataset.countId] = false
		})
	}
	countUp();

	toggleTabHeaderMobile();
	toggleTab();

	const sliderProjectHome = projectHomeSliderInit();
});


function toolNavMapping() {
	try {
		return new MappingListener({
			selector: '.bss-header-1 .tool-nav',
			mobileWrapper: '.bss-header-1 .mobile-header',
			mobileMethod: 'appendTo',
			desktopWrapper: '.bss-header-1 .desktop-header .custom-nav',
			desktopMethod: 'appendTo',
			breakpoint: 992
		}).watch();
	} catch (error) {}
}

function zoneNavMapping() {
	try {
		return new MappingListener({
			selector: '.bss-header-1 .zone-nav',
			mobileWrapper: '.bss-header-1 .mobile-header',
			mobileMethod: 'appendTo',
			desktopWrapper: '.bss-header-1 .desktop-header .custom-nav',
			desktopMethod: 'appendTo',
			breakpoint: 992
		}).watch();
	} catch (error) {}
}

function toggleSearchOnClick() {
	$('body').on('click', '.search-toggle', function () {
		$(this).parent('.search').toggleClass('active');
	});
}

function toggleMobileHeader() {
	$('body').on('click', '.mobile-header-toggle', function () {
		$(this).toggleClass('active');
		$('.mobile-header').toggleClass('active');
	});
}

function bannerHomeInit() {
	return new Swiper('.banner-trangchu .swiper-container', {
		spaceBetween: 0,
		speed: 500,
		loop: true,
		autoplay: true,
		navigation: {
			nextEl: '.banner-trangchu .swiper-button-next',
			prevEl: '.banner-trangchu .swiper-button-prev'
		},
		pagination: {
			el: '.banner-trangchu .swiper-pagination',
			type: 'bullets',
			clickable: true
		}
	});
}

function countUp() {

	$('[data-count]').each(function () {
		let elm = $(this).offset().top
		let docH = $(window).height()
		let docS = $(window).scrollTop()
		let padingTop = 0
		if ($(this).attr('data-top') && $(this).attr('data-top').length) {
			padingTop = parseInt($(this).attr('data-top'));
		}
		let result = (elm + padingTop) - (docH + docS)
		let countId = $(this)[0].dataset.countId
		let run = window[countId]

		if (result <= 0 && !run) {
			let $this = $(this),
				countTo = $this.attr('data-count'),
				durationTo = parseInt($this.attr('data-duration')),
				textAfterCount = $this.attr('data-text-after-count')
			$({ countNum: $this.text() }).animate({
				countNum: countTo
			}, {
					duration: durationTo,
					easing: 'linear',
					step: function () {
						$this.text(Math.floor(this.countNum));
					},
					complete: function () {
						$this.text(this.countNum);
						if (textAfterCount) {
							$this.text(textAfterCount)
						}
						window[countId] = true
					}
				});
		}
	});
}

function toggleTabHeaderMobile() {
	$('body').on('click', '.tab-current', function () {
		$(this).toggleClass('active');
		$(this).parent('.tab-header').find('.tab-links').toggleClass('active');
	});
}

function toggleTab() {
	$('body').on('click', '.tab-link', function () {
		let tabId = $(this).attr('data-tab-header');
		let tabCurrent = tabId.substring(0, 2) + '0';
		let tabToActive = $('main').find(`[data-tab='${tabId}']`);
		tabToActive.parent('.tab-content').children('.tab').removeClass('active');
		tabToActive.addClass('active');
		$('main').find(`[data-current='${tabCurrent}']`).removeClass('active').find('span').html($(this).html()).next();
		$('main').find(`[data-current='${tabCurrent}']`).next().removeClass('active');
		$(this).siblings().removeClass('active')
		$(this).addClass('active');
	});
}

function projectHomeSliderInit() {
	return new Swiper('.home-4 .swiper-container', {
		spaceBetween: 40,
		speed: 500,
		centeredSlides: true,
		slidesPerView: 1.5,
		initialSlide: 1,
		navigation: {
			nextEl: '.home-4 .swiper-button-next',
			prevEl: '.home-4 .swiper-button-prev'
		},
		pagination: {
			el: '.home-4 .swiper-pagination',
			type: 'fraction',
			clickable: true
		}
	});
}
window.onscroll = function() {
	countUp();
}
